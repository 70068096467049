import { Injectable } from '@angular/core';
import { StorageKeyConstants } from '@goatsports/shared/util';
import { CurrentUser } from '../models/current-user.model';
import { DateTime } from 'luxon';
import { RoleType } from '@goatsports/core/data-access';
import { parseJwt } from '../functions/parse-jwt-token';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  private _timeZone!: string;

  constructor() {
    this._timeZone = DateTime.local().zoneName as string;
  }

  get timeZone() {
    return this._timeZone;
  }

  private getCurrentUser() {
    const token = localStorage.getItem(
      StorageKeyConstants.ACCESS_TOKEN_KEY,
    ) as string;
    const decodedToken = parseJwt(token);
    return decodedToken as CurrentUser;
  }

  get currentUserRole() {
    return this.getCurrentUser()?.role as RoleType;
  }

  get currentUserUid() {
    return this.getCurrentUser()?.sub;
  }

  get currentUserEmail() {
    return this.getCurrentUser()?.email;
  }
}
